import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, DatePicker, message } from 'antd';
import Calendar24pxIcon from '@/icons/calendar-24px-icon';
import CircleBlueFillIcon from '@/icons/circle-blue-fill';
import DestinationIcon from '@/icons/destination-icon';
import FtlAi42pxIcon from '@/icons/ftl-ai-42px-icon';
import Reverse32pxIcon from '@/icons/reverse-32px-icon';
import SearchIcon from '@/icons/search-icon';
import { useFTLContext } from '@/services/contexts';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { Gap } from '@/components/gap';
import { MapBox } from '@/pages/search-rate/components/mapbox';
import { apiHooks } from '@/hooks/apis';
import { CitySelector } from './city-selector';
import { QuoteHistory } from './quote-history';

export const GetRates = observer(() => {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const ftlContext = useFTLContext();

	const { runAsync: createFTLquote, loading: createFTLquoteLoading } = apiHooks.useCreateFTLQuote();

	const { data: holidays, loading: holidaysLoading } = apiHooks.useGetHolidays();

	return (
		<div
			className={clsx(
				'relative overflow-y-hidden w-full h-full flex flex-col items-center overflow-hidden',
			)}
		>
			<div className='w-full h-[50vh] bg-blue'>
				<MapBox
					start={ftlContext.pickupAddress?.city}
					to={ftlContext.destinationAddress?.city}
					components={{ distance: true }}
				/>
			</div>

			{/* form */}
			<div className='absolute top-[calc(50vh_-_74px)] z-50'>
				<div
					style={{
						background: `linear-gradient(96.29deg, #228AF9 0%, #80BDFF 98.57%)`,
					}}
					className='m-auto box-border p-[48px_36px_58px_36px] w-[1040px] h-[148px] rounded-[12px] flex items-center gap-[12px]'
				>
					{/* <FtlAi42pxIcon /> */}
					<div className=' box-border p-[0_12px] h-[42px] w-[376px] bg-white rounded-[4px] flex justify-center items-center'>
						<CircleBlueFillIcon />
						<CitySelector
							placeholder={t(`Pickup City / Zip Code`)}
							value={
								ftlContext.pickupAddress
									? {
											label: ftlContext.pickupAddress?.city?.full_name_zipcode ?? '',
											value: `city-${ftlContext.pickupAddress.city.id}`,
									  }
									: undefined
							}
							className='grow'
							onSelect={(address) => (ftlContext.pickupAddress = address)}
						/>
					</div>

					{/* exchange button */}
					<Reverse32pxIcon
						className=' absolute left-[402px] cursor-pointer'
						onClick={() => {
							const tempAddress = { ...ftlContext.pickupAddress };
							ftlContext.pickupAddress = { ...ftlContext.destinationAddress };
							ftlContext.destinationAddress = { ...tempAddress };
						}}
					/>

					<div className=' box-border p-[0_12px] h-[42px] w-[376px] bg-white rounded-[4px] flex justify-center items-center'>
						<DestinationIcon />
						<CitySelector
							placeholder={t(`Destination City / Zip Code`)}
							value={
								ftlContext.destinationAddress
									? {
											label: ftlContext.destinationAddress?.city?.full_name_zipcode ?? '',
											value: `city-${ftlContext.destinationAddress.city.id}`,
									  }
									: undefined
							}
							className='grow'
							onSelect={(address) => (ftlContext.destinationAddress = address)}
						/>
					</div>

					<div className=' h-[42px] w-[192px] flex justify-center items-center bg-white rounded-[4px]'>
						<Calendar24pxIcon />
						{/* <DateRangeOneCalendarPicker /> */}
						<DatePicker
							suffixIcon={null}
							value={ftlContext.pickupDate}
							placeholder={t(`Pickup Date`)}
							variant='borderless'
							className=' body-3-m text-black'
							disabledDate={(current) => {
								if (holidaysLoading) return false;
								return (
									current < dayjs().startOf('day') ||
									holidays?.includes(dayjs(current).format('YYYY-MM-DD'))
								);
							}}
							onChange={(date) => (ftlContext.pickupDate = date)}
						/>
					</div>

					{/* 暂时只有 Dry Van 一种选项，直接在接口写死 */}
					{/* <div className=' box-border p-[0_12px] h-[42px] w-[169px] flex items-center bg-white rounded-[4px]'>
						<DryVan18pxIcon />
						<Select
							variant='borderless'
							labelInValue
							value={ftlContext.selectedEquipment}
							options={Object.entries(FTLParams.equipments).map(([value, label]) => ({
								label,
								value,
							}))}
							className='grow'
							onChange={(option) => {
								ftlContext.selectedEquipment = option;
							}}
						/>
					</div> */}
				</div>

				<div className='m-auto w-fit translate-y-[-27px]'>
					<Button
						type='primary'
						htmlType='submit'
						className={clsx(
							'flex items-center gap-[20px] grow-0 shrink-0 pl-[24px] pr-[24px] w-fit h-[54px] rounded-[30px] bg-[#FFFFFF] shadow-[0px_4px_20px_0px_#FF754C26] font-[600] text-[24px] text-[#007BFF] ',
							{
								'cursor-not-allowed':
									!ftlContext.pickupAddress ||
									!ftlContext.destinationAddress ||
									!ftlContext.pickupDate,
							},
						)}
						loading={createFTLquoteLoading}
						onClick={async () => {
							if (!ftlContext.pickupAddress) {
								message.error(t('field is required', { field: t('Pickup Address') }));
								return;
							}
							if (!ftlContext.destinationAddress) {
								message.error(t('field is required', { field: t('Destination Address') }));
								return;
							}
							if (!ftlContext.pickupDate) {
								message.error(t('field is required', { field: t('Pickup Date') }));
								return;
							}
							const res = await createFTLquote({
								data: {
									pickup_zipcode: ftlContext.pickupAddress.zipcode,
									destination_zipcode: ftlContext.destinationAddress.zipcode,
									pickup_city_id: ftlContext.pickupAddress.city.id,
									destination_city_id: ftlContext.destinationAddress.city.id,
									pickup_date: ftlContext.pickupDate.format('YYYY-MM-DD'),

									// equipment_type: ftlContext.selectedEquipment.value,
									equipment_type: 'DRV',
								},
							});
							ftlContext.quote = res.data.data;
							ftlContext.step = 2;
						}}
					>
						{!createFTLquoteLoading && <SearchIcon active className='w-fit h-[24px]' />}
						{t('Search')}
					</Button>
				</div>
			</div>

			<Gap height='140px' />

			{/* quote history */}
			<div className='m-auto w-[1040px] grow shrink-0 flex flex-col'>
				<div className='grow-0 shrink-0 w-full flex justify-between'>
					<div className='h4-b text-theme-1-1-10'>{t(`Quote history`)}</div>
					<Button
						type='link'
						onClick={() => {
							navigate('/ftl-quote-history');
						}}
					>
						{t(`More`)}
					</Button>
				</div>
				<Gap height='24px' />
				<div className='w-full grow shrink'>
					<QuoteHistory />
				</div>
			</div>
		</div>
	);
});
